@tailwind base;
@tailwind components;
@tailwind utilities;

.output {
  color: white;
  white-space: pre-line;
}

.scrolling {
  overflow-y: scroll;
}

.scrolling::-webkit-scrollbar {
  display: none;
}

p {
  color: white;
}

input {
  caret-color: white;
  color: white;
  background-color: transparent;
  width: 100%;
}

input:focus {
  outline: none;
}

.childButton {
  display: none;
}

#parentButtons:hover .childButton {
  display: block;
}

#blob {
  height: 500px;
  aspect-ratio: 1;
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(to right, #5eb1ff, #ff0099);
  left: 50%;
  top: 50%;
  translate: -50% -50%;
  animation: rotate 10s infinite;
  filter: blur(100px);
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  50% {
    scale: 1 1.5;
  }

  to {
    rotate: 360deg;
  }
}
